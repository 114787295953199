<template>
  <CRow class>
    <CCol col="12">
      <div class="edit-role-container">
        <CCard class="staking-card edit-role-card">
          <CCardBody v-if="isLoading">
            <vcl-list></vcl-list>
          </CCardBody>

          <CCardBody v-if="!isLoading" @change="isFormChange=true">
            <ValidationObserver ref="form">
              <CForm @submit.prevent="onSubmit">
                <CRow>
                  <CCol md="6" lg="6">
                    <ValidationProvider
                      name="ROLE_MANAGEMENT_DETAIL_PAGE_LABEL_NAME"
                      rules="required"
                    >
                      <div slot-scope="{ errors }">
                        <CInput
                          :label="$t('ROLE_MANAGEMENT_DETAIL_PAGE_LABEL_NAME')+ ':'"
                          v-model="name"
                          horizontal
                          class="form-group--custom"
                        />
                        <CRow v-if="errors[0]">
                          <CCol :sm="{offset:'3'}"></CCol>
                          <CCol :sm="9">
                            <span class="error-msg">{{errors[0]}}</span>
                          </CCol>
                        </CRow>
                      </div>
                    </ValidationProvider>
                  </CCol>
                  <CCol md="6" lg="6">
                    <ValidationProvider
                      name="ROLE_MANAGEMENT_DETAIL_PAGE_LABEL_LEVEL"
                      rules="required|min_value:0"
                    >
                      <div slot-scope="{ errors }">
                        <CInput
                          :label="$t('ROLE_MANAGEMENT_DETAIL_PAGE_LABEL_LEVEL')+ ':'"
                          v-model="level"
                          horizontal
                          class="form-group--custom"
                        />
                        <CRow v-if="errors[0]">
                          <CCol :sm="{offset:'3'}"></CCol>
                          <CCol :sm="9">
                            <span class="error-msg">{{errors[0]}}</span>
                          </CCol>
                        </CRow>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <h3>{{$t('ROLE_MANAGEMENT_DETAIL_PAGE_PERMISSIONS_TITLE')}}</h3>
                <ul class="list-group mb-3">
                  <li
                    class="list-group-item list-group-item-light"
                    v-for="groupName in Object.keys(groupPermissons)"
                    :key="groupName"
                  >
                    <h4>{{$t(groupName)}}</h4>

                    <ul class="group-check-per">
                      <li v-for="per in groupPermissons[groupName]" :key="per.id">
                        <label class="checkbox-label">
                          <input type="checkbox" v-model="per.isChecked" />
                          {{(per.displayName)}}
                          <i
                            class="fas fa-exclamation-circle"
                            v-c-tooltip="`${per.displayDescription}`"
                            color="primary"
                          />
                        </label>
                      </li>
                    </ul>
                  </li>
                </ul>

                <CRow v-if="hasSubmited && !hasSelectedPermissions">
                  <CCol lg="12">
                    <span
                      class="error-msg"
                    >{{$t('ROLE_MANAGEMENT_DETAIL_PAGE_PERMISSIONS_ERROR_MGS')}}</span>
                  </CCol>
                </CRow>

                <div class="form-actions">
                  <CButton
                    class="btn-save"
                    type="submit"
                  >{{$t('ROLE_MANAGEMENT_DETAIL_PAGE_BUTTON_SAVE')}}</CButton>
                </div>
              </CForm>
            </ValidationObserver>
          </CCardBody>
        </CCard>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapState } from 'vuex';
import endpoints from '@/constants/endpoints';
import { VclList } from 'vue-content-loading';
import responseHelper from '@/utils/response-helper';
import stringHelper from '@/utils/string-helper';

export default {
  name: 'EditRole',
  components: {
    VclList,
  },
  data() {
    return {
      roleId: '',
      name: '',
      level: null,
      isEdit: false,
      permissions: [],
      groupPermissons: {},
      hasSubmited: false,
      isLoading: true,
    };
  },
  computed: {
    ...mapState({
      role: state => state.role.roleSelected,
      hasSelectedPermissions() {
        return Object.keys(this.groupPermissons).some(groupName => {
          const permissions = this.groupPermissons[groupName];

          return permissions.some(x => x.isChecked);
        });
      },
    }),
  },

  async mounted() {
    const roleId = _.trim(this.$route.params.roleId);

    if (roleId) {
      this.roleId = roleId;

      if (this.role.id == roleId) {
        this.isEdit = true;
        this.name = this.role.name;
        this.level = this.role.level;

        await this.getPermissions();
      } else {
        this.$route.push('/admin/roles');
      }
    } else {
      this.isEdit = false;
      await this.getPermissions();
    }
  },

  methods: {
    ...mapActions(['addRole', 'updateRole']),
    async getPermissions() {
      const params = {
        limit: 100000,
        offset: 0,
      };
      const [allPermissionsResult, permissionPerRoleResult] = await Promise.all([
        this.$http.get(endpoints.getPermissions, { params: params }),
        this.roleId ? this.$http.get(endpoints.getRoleHasPermissions(this.roleId)) : [],
      ]);

      const permissionPerRole = permissionPerRoleResult.data || [];
      const permissions = allPermissionsResult.data.items.map(item => {
        item.isChecked = permissionPerRole.find(x => x.id === item.id);
        item.displayName = responseHelper.getMessageByCode('PERMISSION_PAGE_' + item.name.trim());
        item.displayDescription = responseHelper.getMessageByCode(
          'PERMISSION_PAGE_DESCRIPTION_' + stringHelper.underscoredUpper(item.description),
        );
        return item;
      });
      const groupPermissons = _.groupBy(permissions, 'group_name');

      _.forEach(groupPermissons, function(value, key) {
        var newKey = `ROLE_MANAGEMENT_DETAIL_PAGE_LABEL_GROUP_PERMISSION_TITLE_${stringHelper.underscoredUpper(key)}`;
        if (_.includes(_.keys(groupPermissons), key)) {
          groupPermissons[newKey] = value;

          delete groupPermissons[key];
        }
      });

      this.groupPermissons = groupPermissons;

      this.isLoading = false;
    },
    async onSubmit() {
      this.hasSubmited = true;

      this.$refs.form.validate().then(isValid => {
        if (!isValid || !this.hasSelectedPermissions) {
          return;
        }

        if (this.isEdit) {
          this.update();
        } else {
          this.create();
        }
      });
    },

    async create() {
      let permission_ids = [];
      Object.keys(this.groupPermissons).forEach(groupName => {
        const permissions = this.groupPermissons[groupName];

        permission_ids = permission_ids.concat(permissions.filter(x => x.isChecked).map(x => x.id));
      });

      const role = {
        name: this.name,
        level: this.level,
        permission_ids,
      };

      await this.addRole(role);
    },

    async update() {
      let permission_ids = [];
      Object.keys(this.groupPermissons).forEach(groupName => {
        const permissions = this.groupPermissons[groupName];

        permission_ids = permission_ids.concat(permissions.filter(x => x.isChecked).map(x => x.id));
      });

      const updateRole = {
        id: this.role.id,
        name: this.name,
        level: this.level,
        permission_ids,
      };

      await this.updateRole(updateRole);
    },
  },
};
</script>
<style lang="scss">
.edit-role-container {
  padding-left: 9px;
  @media only screen and (max-width: 1024px) {
    padding: 0 15px;
  }

  .edit-role-card .card-body {
    padding: 32px 15px;

    .group-check-per {
      list-style: none;
      padding: 0;
      display: flex;
      flex: 0 0 100%;
      flex-wrap: wrap;
      h3 {
        width: 100%;
        font-size: 18px;
        // text-transform: uppercase;
        margin-bottom: 15px;
        color: #657187;
        font-size: 18px;
        line-height: 24px;
      }
      li {
        width: 33.33333%;
        margin-bottom: 5px;
        display: flex;
        padding: 0 5px;
        @media only screen and (max-width: 767px) {
          width: 100%;
        }
        input {
          margin: 5px 5px 0 0;
          position: relative;
          top: 1.5px;
        }
        label {
          width: unset;
          font-size: 14px;
          text-align: left;
          line-height: 22px;
        }
      }
    }
    .form-row {
      margin-top: 1em;
      margin-bottom: 5px;
    }
    .error-msg {
      color: #ff0000;
    }

    .form-actions {
      text-align: right;

      .btn-save {
        min-width: 146px;
        height: 36px;
        border-radius: 4px;
        background-color: #3b4b7e;
        color: #ffffff;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
        border: none;
        outline: none !important;
        -webkit-box-shadow: none;
        box-shadow: none;
        text-align: center;
        @media only screen and (max-width: 1024px) {
          padding-right: 0;
        }
      }
    }
  }
}
</style>
